import {Injectable} from '@angular/core';
import {Arianee} from '@arianee/arianeejs';
import {ArianeeWallet} from '@arianee/arianeejs/dist/src/core/wallet';
import {NETWORK} from '@arianee/arianeejs/dist/src';
import {ConfigurationService} from "./configuration.service";
import {ArianeeWalletBuilder} from "@arianee/arianeejs/dist/src/core/wallet/walletBuilder";

@Injectable({
  providedIn: 'root'
})
export class ArianeeService {


  public network: string;
  public $arianee: Promise<ArianeeWalletBuilder>;

  public readWallet: Promise<ArianeeWallet>;

  constructor(private configurationService: ConfigurationService) {
    this.initArianee();
    this.readWallet = this.$arianee.then(d => d.readOnlyWallet());
  }

  private getArianee = async (): Promise<ArianeeWalletBuilder> => {
    const {network} = await this.configurationService.getConfiguration();
    this.network = network;
    return new Arianee().init(network as NETWORK);
  }

  private initArianee = async (): Promise<ArianeeWalletBuilder> => {
    if (!this.$arianee) {
      this.$arianee = this.getArianee();
    }

    return this.$arianee;
  }
}
