export const environment = {
  production: false,
  backendURL: 'https://us-central1-mainnet-credit-manager.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyDhENSFEAbElCPByGsopH5vyHD3H5Rdcqw',
    authDomain: 'mainnet-credit-manager.firebaseapp.com',
    projectId: 'mainnet-credit-manager',
    storageBucket: 'mainnet-credit-manager.appspot.com',
    messagingSenderId: '773231308648',
    appId: '1:773231308648:web:31c3e723b634ee4a7af0dd'
  }
};
