import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  public getConfiguration = async (): Promise<{ network: string }> => {
    const url = `${environment.backendURL}/configuration/configuration`;
    return this.httpClient.get<any>(url, {
      observe: 'body',
      responseType: 'json'
    }).toPromise();
  }
}
