import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {groupBy} from 'lodash';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {combineLatest, Subject} from 'rxjs';
import {ArianeeService} from '../services/arianee.service';
import {get} from 'lodash';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss']
})
export class ConfigurationPageComponent implements OnInit {

  public addressToAddForm = new FormGroup({
    address: new FormControl(),
    name: new FormControl(),
    apiKey: new FormControl(),
    endpoint: new FormControl()
  });
  public readonly creditTypes = [
    'update',
    'message',
    'certificate',
    'event'].sort();
  public forms: FormGroup;
  public balances = {};
  public bdhConfigs;
  public buyConfigs;
  public reservedConfigs;

  public addresses: string[];
  private $takeUntil = new Subject();

  public get network() {
    return this.arianeeService.network;
  }
  constructor(private fireStore: AngularFirestore,
              private arianeeService: ArianeeService,
              private httpClient: HttpClient
  ) {
  }

  public docWithId = (data) => {
    const docs = data.map(d => {
      const doc = d.payload.doc.data();
      return {
        ...doc as any,
        id: d.payload.doc.id
      };
    });

    return docs;
  };


  public getBalanceOf(address, creditType) {
    if (this.balances[address] && this.balances[address][creditType]) {
      return this.balances[address][creditType];
    } else {
      return '__';
    }
  }

  public findBalanceOfReserved = () => {
    const configs = this.addresses
      .filter(address => this.hasConfigAPI(address))
      .map(address => this.getConfigFromAddress(address));
    configs
      .forEach(async (conf) => {
        const {apiKey, endpoint, address} = conf;
        const {count} = await this.httpClient.get<any>(endpoint, {
          observe: 'body',
          responseType: 'json',
          headers: {
            'x-api-key': apiKey
          },
        }).toPromise()
          .catch(e => {
            console.error(e);
            return {
              count: 'error'
            }
          });

        this.balances[address].reserved = count;
      })
  };

  public async findBalanceOfCredit() {
    const wallet = await this.arianeeService.readWallet;
    return Promise.all(this.addresses.map(address => {
      this.balances[address] = {...this.balances[address]};
      return Promise.all(this.creditTypes.map(async creditType => {
        const balance = await wallet.methods.balanceOfCredit(creditType, address);
        this.balances[address][creditType] = balance;
      }));
    }));
  }

  ngOnInit() {

    combineLatest([
      this.fireStore.collection('configurations')
        .snapshotChanges()
        .pipe(map(this.docWithId)),
      this.fireStore.collection('buy')
        .snapshotChanges()
        .pipe(map(this.docWithId)),
      this.fireStore.collection('reserved')
        .snapshotChanges()
        .pipe(map(this.docWithId))
    ])
      .subscribe(data => {
        this.$takeUntil.next('');
        const [configs, buyConfig, reservedConfig] = data;
        this.bdhConfigs = configs;
        this.addresses = this.bdhConfigs.map(d => d.address);
        this.buyConfigs = groupBy(buyConfig, 'address');
        this.reservedConfigs = groupBy(reservedConfig, 'address');
        this.forms = new FormGroup({});

        this.findBalanceOfCredit();
        this.findBalanceOfReserved();

        this.addresses.forEach(address => {
          const formGroup = new FormGroup({});

          const reservedFormControl = new FormControl(0);
          // add reserved form
          const reservedConfigForThisAddress = get(this.reservedConfigs, `${address}[0]`);
          formGroup.addControl('reserved', reservedFormControl);

          if (reservedConfigForThisAddress) {
            reservedFormControl.setValue(reservedConfigForThisAddress.number);
          }

          if (!this.hasConfigAPI(address)) {
            formGroup.disable();
          } else {
            reservedFormControl.valueChanges
              .pipe(takeUntil(this.$takeUntil))
              .pipe(
                debounceTime(1000),
                filter(value => value > 0)
              ).subscribe(reservedValue => {
              const id = (reservedConfigForThisAddress && reservedConfigForThisAddress.id) || uuidv4();
              this.setReservedConfig(id, {address, number: reservedValue});
            });
          }

          formGroup.addControl('reserved', reservedFormControl);

          // add credit forms
          this.creditTypes
            .forEach(type => {
              const config = this.getBuyConfigForAddress(address).find(d => d.type === type);
              const num = config && config.number ? config.number : 0;
              const control: FormControl = new FormControl(num);
              control.valueChanges
                .pipe(takeUntil(this.$takeUntil))
                .pipe(
                  debounceTime(1000),
                  filter(value => value > 0)
                ).subscribe(value => {
                const id = (config && config.id) || uuidv4();
                this.setBuyConfig(id, {
                  type,
                  address,
                  number: value
                });
              });

              formGroup.addControl(type, control);
            });

          this.forms.addControl(address, formGroup);

        });
      });

  }

  public getBuyConfigForAddress = (address: string): any[] => {
    return this.buyConfigs[address] || [];
  };


  public getConfigFromAddress = (address: string) => this.bdhConfigs.find(d => d.address === address);

  public hasConfigAPI = (address: string): boolean => {
    const conf = this.getConfigFromAddress(address);
    if (conf) {
      return !!(conf.apiKey && conf.endpoint);
    } else {
      return false;
    }
  };

  public getNameFromAddress = (address: string) => {
    const conf = this.getConfigFromAddress(address);
    if (conf) {
      return conf.name;
    } else {
      return `unamed ${address}`;
    }
  };

  public setBuyConfig = (id: string, config: { type, address, number }) => {
    return this.fireStore.collection('buy')
      .doc(id)
      .set({
        id,
        ...config
      });
  };

  public setReservedConfig = (id: string, config: { address, number }) => {
    return this.fireStore.collection('reserved')
      .doc(id)
      .set({
        id,
        ...config
      });
  };

  async addAddress() {
    const {address, name, endpoint, apiKey} = this.addressToAddForm.getRawValue();
    await this.fireStore.collection('configurations')
      .doc(address)
      .set({
        address,
        name,
        apiKey,
        endpoint
      });

    this.addressToAddForm.reset();
  }
}
