import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  public loginForm: FormGroup;

  constructor(private angularFireAuth: AngularFireAuth, private router: Router) {

  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  submit = async () => {
    if (this.loginForm.valid) {
      const {email, password} = this.loginForm.getRawValue();
      try {
        await this.angularFireAuth.auth
          .signInWithEmailAndPassword(email, password);
        alert('success');

        this.router.navigate(['configuration-list']);

      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      }
    } else {
      alert('please check the form');
    }
  }
}
